<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
									<span class="tct_tit">{{$t('i18nn_1432b6fd3fd542c5')}}</span>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<div class="filterCon" style="">
									<ul class="filterConList">
										<li>
											<span>{{$t('i18nn_54672b9140a3afe0')}}</span>
											<el-select v-model="printerVal" :placeholder="$t('hytxs0000100')" filterable
												clearable :disabled="print_loading" style="margin-left: 10px;"
												@change="changePrinter">
												<el-option v-for="(item, index) in printerList" :key="index"
													:label="item.name" :value="item.value"></el-option>
											</el-select>
										</li>
										<li>
											<span>{{$t('i18nn_a85af4ba66084d28')}}</span>
											<el-select filterable v-model="printerSize"
												:placeholder="$t('2ad108ab2c560530')" style="width: 100px;">
												<el-option v-for="(item, index) in sizeList" :key="index"
													:label="'en'==$i18n.locale?item.labelEn:item.label" :value="item.value"></el-option>
											</el-select>
										</li>
									</ul>
								</div>
							</el-col>
						</el-row>
					</div>

					<div>
						<el-card style="" :body-style="{ padding: '10px 20px' }">
							<div>
								<div v-if="isShowBatchNoInput">
									<!-- <div v-if="!isBatchNoData"> -->
									<el-input ref="batchNo" type="text" v-model="filterData.batchNo" maxlength="50"
										clearable :placeholder="$t('i18nn_a73f09a050db50e5')"
										@keyup.enter.native="getPrintInfoByBatchAction()" style="width: 800px;">
										<template slot="prepend">
											<i class="el-icon-notebook-2"></i>
											<span>{{$t('i18nn_b458f03c43a90ee9')}}</span>:
										</template>
										<el-button slot="append" type="warning" icon="el-icon-search"
											@click="getPrintInfoByBatchAction()">{{$t('1e7246dd6ccc5539')}}</el-button>
									</el-input>

									<el-button type="primary" plain @click="clearBatchNo()" icon="el-icon-circle-close"
										style="margin-left:10px;">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
									<!-- </div> -->
									<!-- <el-card shadow="never"> -->

									<!-- </el-card> -->
								</div>
								<div style="display:flex;" v-if="isBatchNoData">
									<el-descriptions title="" size="" :column="4"
										style="width: 800px; margin-top: 10px;">
										<el-descriptions-item :label="$t('i18nn_b458f03c43a90ee9')">
											<strong>{{filterData.batchNo}}</strong>
										</el-descriptions-item>
										<el-descriptions-item :label="$t('i18nn_b0929817d371ed27')">
											<strong
												style="color:#409EFF;font-size: 18px;">{{printBatchInfo.orderTotal}}</strong>
										</el-descriptions-item>
										<el-descriptions-item :label="$t('i18nn_89ecaa622b09b1a0')">
											<strong
												style="color:#67C23A;font-size: 18px;">{{printBatchInfo.canScan}}</strong>
										</el-descriptions-item>
										<el-descriptions-item :label="$t('i18nn_dd05e02f1d454c5c')">
											<strong
												style="color:#E6A23C;font-size: 18px;">{{printBatchInfo.printed}}</strong>
										</el-descriptions-item>
									</el-descriptions>
									<el-button type="text" icon="el-icon-view" @click="showPrintDet()"><span>{{$t('i18nn_3f907fa53cbe61e3')}}</span>/<span>{{$t('i18nn_494ffc9644d87900')}}</span>
									</el-button>
								</div>

							</div>
						</el-card>

						<el-tabs v-model="tabActiveName" type="border-card" v-if="isBatchNoData"
							@tab-click="handleClick">
							<el-tab-pane :label="$t('i18nn_7327bf9113497eb5')" name="first">
								<BatchScanPrintSingle ref="BatchScanPrintSingle" :openTime="singlePrintOpenTime"
									:printBatchInfo="printBatchInfo" :batchNo="filterData.batchNo"
									:isBatchNoData="isBatchNoData" :printerVal="printerVal" :printerSize="printerSize" 
									@updateInfo="updateBatchInfo" @loadingBack="loadingBack">
								</BatchScanPrintSingle>
							</el-tab-pane>
							<el-tab-pane :label="$t('i18nn_e4300f029469a8c2')" name="second">
								<BatchScanPrintBatch ref="BatchScanPrintBatch" :openTime="batchPrintOpenTime"
									:printBatchInfo="printBatchInfo" :batchNo="filterData.batchNo"
									:isBatchNoData="isBatchNoData" :printerVal="printerVal" :printerSize="printerSize" 
									@updateInfo="updateBatchInfo" @loadingBack="loadingBack">
								</BatchScanPrintBatch>
							</el-tab-pane>
						</el-tabs>

					</div>
				</div>
			</div>
		</div>
		<!--查看已打未打-->
		<BatchScanPrintDetList :openTime="ScanPrintDetOpenTime" :batchNo="filterData.batchNo"></BatchScanPrintDetList>
	</div>
</template>
<script>
	import BatchScanPrintSingle from '@/components/WarehouseCenter2/DropShipping/BatchScanPrintSingle.vue';

	import BatchScanPrintBatch from '@/components/WarehouseCenter2/DropShipping/BatchScanPrintBatch.vue';

	import BatchScanPrintDetList from '@/components/WarehouseCenter2/DropShipping/BatchScanPrintDetList.vue';

	import {
		// priterPdfBase64,
		GetPrinterList,
		savePrtVal,
		getPrtVal,
		priterSizeList
	} from '@/utils/LodopPrinter.js';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },

		components: {
			BatchScanPrintDetList,
			BatchScanPrintSingle,
			BatchScanPrintBatch
		},
		data() {
			return {

				// drawerScanSku: false,

				loading_load: false,

				tabActiveName: "first",

				singlePrintOpenTime: "",
				batchPrintOpenTime: "",
				// activeName: 'first',
				// loading_det_load: false,

				// loading_count: false,
				// countData: {},
				printBatchInfo: {},
				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				// tableData: [],
				// currentData: {},

				print_loading: false,

				// scanList: [],

				ScanPrintDetOpenTime: '',
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 500],
				// 	page_size: 500,
				// 	total: 0
				// },
				// countPdfLabel: 0,
				// skuQuantity: 0,

				printerVal: "",
				printerSize: "1",
				printerList: [],
				sizeList: priterSizeList(),

				// selectOption: {
				// 	wh_no: [],
				// },
				isShowBatchNoInput: true,
				isBatchNoData: false,
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序

					batchNo: '',

					// goodsSku: '',

				}
			};
		},
		// watch: {
		// 	$route(to, from) {
		// 		console.log('WhDropShipping $route', to, from);
		// 	}
		// },
		// beforeRouteUpdate(to, from, next) {
		// 	console.log('WhDropShipping beforeRouteUpdate', to, from);
		// 	next();
		// },
		activated() {

			console.log('WhDropShippingBatchScanPrint activated');
			this.init();
		},
		//创建时
		created() {
			console.log('WhDropShippingBatchScanPrint created');
			// this.init();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			init() {
				this.clearFilter();
				if (this.$route.query && this.$route.query.batchNo) {
					this.filterData.batchNo = this.$route.query.batchNo;
					this.isShowBatchNoInput = false;
					// this.initData();
				} else {
					this.isShowBatchNoInput = true;
					this.isBatchNoData = false;
				}
				//查询拣货单号信息
				if (this.filterData.batchNo) {
					this.getPrintInfoByBatch();
				}

				this.tabActiveName = "first";

				this.singlePrintOpenTime = new Date().getTime();
				// this.tableData = [];
				// this.scanList = [];
				// this.currentData = {};
				this.initPriterList();
				//设置上次打印机的值
				this.printerVal = getPrtVal();

				//自动获取焦点
				this.$nextTick(() => {
					if (!this.filterData.batchNo) {
						if (this.$refs.batchNo) {
							this.$refs.batchNo.focus();
						}
					} else {
						this.skuInputFocus();
					}
				});
			},

			skuInputFocus() {
				if ('first' == this.tabActiveName) {
					this.$nextTick(() => {
						if (this.$refs.BatchScanPrintSingle) {
							this.$refs.BatchScanPrintSingle.skuInputFocus();
						}
					});
				} else {
					this.$nextTick(() => {
						if (this.$refs.BatchScanPrintBatch) {
							this.$refs.BatchScanPrintBatch.skuInputFocus();
						}
					});
				}
			},

			handleClick(tab, event) {
				console.log(tab, event);
				this.skuInputFocus();
			},

			//清空拣货单号
			clearBatchNo() {
				this.filterData.batchNo = "";
				this.isShowBatchNoInput = true;
				this.isBatchNoData = false;
				// this.scanList = [];
				// this.currentData = {};
				//自动获取焦点
				this.$nextTick(() => {
					if (this.$refs.batchNo) {
						this.$refs.batchNo.focus();
					}
				});
				this.singlePrintOpenTime = new Date().getTime();
			},
			// initData() {
			// 	if (!this.filterData.batchNo) {
			// 		this.$message.warning(this.$t('i18nn_c4189210d2599fd7'));
			// 		return;
			// 	}
			// 	if (!this.filterData.goodsSku) {
			// 		this.$message.warning("请扫描或者输入SKU");
			// 		return;
			// 	}
			// 	//保存此次打印机的值
			// 	// savePrtVal(this.printerVal);
			// 	// this.currentData = {};
			// 	//调用接口
			// 	this.getPageData();
			// 	//扫描成功
			// 	this.scanSuccessAction();
			// },
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingBatchDet'
				// 	});
				// }
			},
			// toggleDrawerScanSku() {
			// 	this.drawerScanSku = !this.drawerScanSku;
			// },
			clearFilter() {
				Object.getOwnPropertyNames(this.filterData).forEach(key => {
					// console.log(key,obj[key]);
					// if ('orderBy' != key && 'sortAsc' != key) {
					this.filterData[key] = '';
					// }
				});
				// this.$nextTick(() => {
				// 	this.$refs.cusSelFuzzy.clearData();
				// });
			},
			//保存选择的打印机
			changePrinter(v) {
				//保存此次打印机的值
				savePrtVal(this.printerVal);
			},

			//初始化打印机列表
			initPriterList() {
				let printerList = GetPrinterList();
				console.log('printerList', printerList);
				this.printerList = printerList;
			},

			//查看已打或未打
			showPrintDet() {
				this.ScanPrintDetOpenTime = new Date().getTime();
			},

			//分页的筛选项数据
			// pageFilterData() {
			// 	let scanType = ''; //1：sku  2：运单号
			// 	if ('second' == this.activeName) {
			// 		scanType = '2';
			// 	} else {
			// 		scanType = '1';
			// 	}
			// 	return {
			// 		batchNo: this.filterData.batchNo ? this.filterData.batchNo : null,
			// 		"keyword": this.filterData.goodsSku ? this.filterData.goodsSku : null,
			// 		"userId": this.printBatchInfo.userId ? this.printBatchInfo.userId : null,
			// 		"scanType": scanType
			// 	};
			// },
			//
			// getPageData() {
			// 	//防止扫码过快
			// 	if (this.loading_load) {
			// 		this.$message.warning("正在加载,请稍候");
			// 		return;
			// 	}
			// 	let filterData = {};
			// 	//如果 0==printStatus，把上次结果回传
			// 	if ('0' === this.currentData.printStatus) {
			// 		filterData = Object.assign(this.currentData, this.pageFilterData());
			// 	} else {
			// 		filterData = this.pageFilterData();
			// 	}
			// 	this.currentData = {};
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhDsScanPrint, filterData)
			// 		.then(async ({
			// 			data
			// 		}) => {
			// 			
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				let currentData = data.data;
			// 				if (1 == currentData.printStatus && currentData.printLabel && currentData.printLabel >
			// 					0) {
			// 					this.currentData = currentData;
			// 					// this.$message.warning("已打印过此面单，如需重新打印，请点击‘重新打印’按钮");
			// 					this.$alert(
			// 						`<div style="color:red;">已打印过此面单；<br />如继续打印，<br />请点击'<strong> 重新打印 </strong>'按钮</div>`,
			// 						this.$t('cc62f4bf31d661e3'), {
			// 							type: 'warning',
			// 							dangerouslyUseHTMLString: true
			// 						});
			// 					return;
			// 				}
			// 				//当前数据总条数
			// 				// this.pagination.total = parseInt(data.total);
			// 				//清空SKU并调用打印
			// 				let obj = {};
			// 				// let isRepeat = this.tableData;
			// 				// if(isRepeat){//是否重复
			// 				// 	obj = data.data;
			// 				// 	obj.isRepeat = true;
			// 				// } else {//打印
			// 				obj = await this.autoPrint(currentData);
			// 				// obj.scanSkuCode = filterData.keyword;
			// 				// }
			// 				//表格显示数据
			// 				// this.tableData = this.tableData.concat([obj]);
			// 				this.currentData = obj;
			// 			} else {
			// 				let errorMsg = data.msg ? data.msg : '查询该批次SKU失败';
			// 				this.$message.warning(errorMsg);
			// 				let obj = {
			// 					// scanSkuCode: filterData.keyword,
			// 					httpError: "1",
			// 					errorMsg: errorMsg
			// 				};
			// 				// this.tableData = this.tableData.concat([obj]);
			// 				this.currentData = obj;
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			let errorMsg = data.msg ? data.msg : '查询该批次SKU失败！';
			// 			this.$message.error(errorMsg);
			// 			let obj = {
			// 				// scanSkuCode: filterData.keyword,
			// 				httpError: "2",
			// 				errorMsg: errorMsg
			// 			};
			// 			// this.tableData = this.tableData.concat([obj]);
			// 			this.currentData = obj;
			// 			this.loading_load = false;
			// 		});
			// },
			//扫描成功
			// scanSuccessAction() {
			// 	this.scanList.unshift({
			// 		scanCode: this.filterData.goodsSku
			// 	});
			// 	this.filterData.goodsSku = "";
			// 	// this.$nextTick(() => {
			// 	// 	this.$refs.sku.focus();
			// 	// })
			// 	this.skuInputFocus();
			// },
			//调用打印
			// async autoPrint(data) {
			// 	let item = data;

			// 	let reData = {};
			// 	//扫描完成才可以打印
			// 	if (1 == item.printStatus) {
			// 		item.sku = data.goodsSku;
			// 		item.quantity = data.quantity;
			// 		let base64 = data.pdfBase64;
			// 		let printSeq = data.printSeq;
			// 		reData = await priterPdfBase64('ScanPrint', item, base64, printSeq, null, this.printerVal, this
			// 			.printerSize);
			// 	} else {
			// 		reData = item;
			// 	}
			// 	// this.countPdfLabelAction(item.pdfNum, item.quantity);
			// 	return reData;
			// },
			//重新打印
			// rePrint() {
			// 	this.autoPrint(this.currentData);
			// },
			//拣货单号基本信息
			getPrintInfoByBatchAction() {
				if (!this.filterData.batchNo) {
					this.$message.warning(this.$t('i18nn_5d756d95aa048878'));
					return;
				}
				this.isBatchNoData = false;
				this.getPrintInfoByBatch();
			},
			//更新数据
			updateBatchInfo(){
				if (!this.filterData.batchNo) {
					this.$message.warning(this.$t('i18nn_5d756d95aa048878'));
					return;
				}
				this.getPrintInfoByBatch();
			},
			loadingBack(val){
				this.loading_load = val;
			},
			//查询批次数据
			getPrintInfoByBatch() {
				//防止扫码过快
				if (this.loading_load) {
					this.$message.warning(this.$t('i18nn_b954f8829728e9d2'));
					return;
				}
				// this.isBatchNoData = false;
				let filterData = {
					"batchNo": this.filterData.batchNo
				}
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhDsScanPrintInfo, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;

						if (200 == data.code && data.data) {
							this.isBatchNoData = true;
							this.printBatchInfo = data.data;
							// this.$nextTick(() => {
							// 	this.$refs.sku.focus();
							// });
							this.skuInputFocus();

						} else {
							let errorMsg = data.msg ? data.msg : this.$t('i18nn_45ec4789fbc5c60f');
							this.$message.warning(errorMsg);
						}
					})
					.catch(error => {
						console.log(error);
						let errorMsg = error.msg ? error.msg : this.$t('i18nn_45ec4789fbc5c60f')+'!';
						this.$message.error(errorMsg);
					});
			},
			//计算总面单数
			// countPdfLabelAction(pdfNum, skuQuantity) {
			// 	if (pdfNum) {
			// 		this.countPdfLabel = this.countPdfLabel + parseInt(pdfNum);
			// 	}
			// 	if (skuQuantity) {
			// 		this.skuQuantity = this.skuQuantity + parseInt(skuQuantity);
			// 	}
			// },
			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },
			// //多选
			// handleSelectionChange2(val) {
			// 	console.log(val);
			// 	// this.multipleSelection2 = val;
			// },
			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// .scanCon {
	// 	display: flex;
	// 	margin-top: 10px;
	// }

	// .scanLeft {
	// 	width: 600px;
	// 	// width: 1000px;
	// 	// flex:1;
	// 	margin: 0 10px;
	// }

	// .scanRight {
	// 	// width: 300px;
	// 	min-width: 1000px;
	// 	max-width: 2000px;
	// 	flex: 1;

	// 	.scanRiCon {
	// 		overflow: auto;
	// 	}
	// }

	// .scanCodeList {
	// 	li {
	// 		margin: 10px 0;
	// 	}
	// }

	// .scanCard {
	// 	position: relative;
	// 	.scanTips {
	// 		position: absolute;
	// 		right:0;
	// 		top:0;

	// 	}
	// }

	// .scanList {
	// 	// padding: 10px;
	// 	// overflow: auto;

	// 	.scanItem {
	// 		margin-bottom: 10px;

	// 		.scanLine {
	// 			margin: 10px;
	// 			display: flex;

	// 			// justify-content: space-between;
	// 			.scanLineData {
	// 				padding: 0 10px;
	// 				;
	// 			}
	// 		}
	// 	}
	// }

	// .descRecLabel {
	// 	// width: 300px;
	// 	// text-align: right;
	// 	// color:red;
	// 	background: #fff;
	// }
	// .scanInfoContent {
	// 	display: flex;
	// 	.scanInfoLeft {

	// 	}
	// 	.scanInfoRight {

	// 	}
	// }
</style>
